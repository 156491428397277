import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CollapseData = ({
  open,
  setOpen,
  component,
  children,
  collapseTitle,
  className,
  category,
  tags,
}) => {
  const [openArticles, setOpenArticles] = useState(false);
  const { statusDeleteSegmentFromConversation } = useSelector(
    (state) => state.segments
  );
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {component ? (
        component
      ) : (
        <TableRow
          className="row-collapse"
          component="div"
          onClick={() =>
            category ? setOpenArticles(!openArticles) : setOpen(!open)
          }
        >
          <TableCell
            className={
              statusDeleteSegmentFromConversation === "loading" &&
              collapseTitle === t("Segments For Conversation")
                ? "cell-text-collapse delete-segment"
                : "cell-text-collapse"
            }
            component="div"
          >
            {collapseTitle === t("Segments For Conversation")
              ? statusDeleteSegmentFromConversation === "loading"
                ? t("Deleting segment...")
                : collapseTitle
              : collapseTitle}
          </TableCell>
          <TableCell
            className="cell-btn-collapse"
            align="right"
            component="div"
          >
            <IconButton aria-label="expand row" size="small">
              {category ? (
                openArticles ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )
              ) : open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
      )}

      <TableRow style={{ display: tags && !open && "none" }}>
        <TableCell
          className={
            className ? `${className} collapse-content` : "collapse-content"
          }
          colSpan={6}
        >
          <Collapse
            in={category ? openArticles : open}
            timeout="auto"
            unmountOnExit
          >
            {children}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default CollapseData;
